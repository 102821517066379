export const kA11yMetadata = 'a11y-metadata';
export const kAccessibility = 'accessibility';
export const kCookiePopup = 'cookie-popup';
export const kCookieWidget = 'cookie-widget';
export const kCookiesInventory = 'cookies-inventory';
export const kCookiesPolicy = 'cookies-policy';
export const kDataProtectionNotice = 'data-protection-notice';
export const kElectionResults = 'election-results';
export const kElectionResults2024 = 'election-results-2024';
export const kHomepage = 'homepage';
export const kHowElectionsWork = 'how-elections-work';
export const kHowToVote = 'how-to-vote';
export const kIndex = 'index';
export const kLanguages = 'languages';
export const kLegalNotice = 'legal-notice';
export const kMetadata = 'metadata';
export const kPledgeToVote = 'pledge-to-vote';
export const kPrivacyPolicy = 'privacy-policy';
export const kWhyVote = 'why-vote';
export const kSitemap = 'sitemap';
export const kTranscripts = 'transcripts';
export const kUseYourVote = 'use-your-vote';
export const kCountries = 'countries';
export const kLoading = 'loading';
export const kEasyToRead = 'easy-to-read';
export const kAbroad = 'abroad';
export const kIntegrity = 'free-fair-elections';
export const kElectionResultsMenu = 'election-results-menu';
export const kHeroMovie = 'video';
export const kAfterTheElections = 'after-the-elections';
export const kPortal = 'portal';
export const kErrorPage = 'error-page';
export const kEPPresident = 'parliament-president';
export const kECPresident = 'commission-president';
export const kCommissioners = 'european-commission';
export const kCommissionerIDs = 'commissioner-ids';
export const kHearingsDocuments = 'hearings-documents';

export const kMarkdownFieldsWithKeys = {
  [kAfterTheElections]: {
    name: 'ateAnswer',
    keys: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
  },
  [kHowElectionsWork]: {
    name: 'hewAnswer',
    keys: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
  },
  [kLegalNotice]: {
    name: 'lnText',
    keys: [2, 3, 4],
  },
  [kPrivacyPolicy]: {
    name: 'ppText',
    keys: [2, 3, 4],
  },
  [kAccessibility]: { name: 'a11yText', keys: [2, 3, 4, 5, 6] },
  [kDataProtectionNotice]: {
    name: 'dpnText',
    keys: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  },
  [kCookiesPolicy]: {
    name: 'cpText',
    keys: [2, 3, 4, 5, 6, 7],
  },
  [kCookiesInventory]: {
    name: 'ciAnswer',
    keys: [1, 2, 3],
  },
  [kEasyToRead]: {
    name: 'description',
    keys: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  },
  [kIntegrity]: {
    name: 'answer',
    keys: [1, 2, 3, 4, 5, 6, 7],
  },
  [kUseYourVote]: {
    name: 'uyvPageText',
    keys: [1, 2, 3, 4],
  },
};

export const languageCodes = [
  'en',
  'bg',
  'hr',
  'cs',
  'da',
  'et',
  'fi',
  'fr',
  'ga',
  'de',
  'el',
  'hu',
  'it',
  'lv',
  'lt',
  'mt',
  'nl',
  'pl',
  'pt',
  'ro',
  'sk',
  'sl',
  'es',
  'sv',
];

export const languagesByCountry = {
  at: ['de'],
  be: ['nl', 'fr', 'de'],
  bg: ['bg'],
  gr: ['el'],
  cy: ['el'],
  cz: ['cs'],
  dk: ['da'],
  ee: ['et'],
  lv: ['lv'],
  fr: ['fr'],
  de: ['de'],
  pt: ['pt'],
  sk: ['sk'],
  ie: ['ga'],
  it: ['it'],
  pl: ['pl'],
  lt: ['lt'],
  fi: ['fi', 'sv'],
  mt: ['mt'],
  nl: ['nl'],
  hr: ['hr'],
  hu: ['hu'],
  ro: ['ro'],
  lu: ['fr', 'de'],
  si: ['sl'],
  es: ['es'],
  se: ['sv'],
};

export const xExceptions = new Map([
  ['cs', 'cz'],
  ['da', 'dk'],
  ['ee', 'et'],
  ['gr', 'el'],
  ['ie', 'ga'],
]);

export const xAccount = (countryOrLanguage) => {
  const key = countryOrLanguage.toLowerCase();

  return xExceptions.has(key)
    ? `Europarl_${xExceptions.get(key).toUpperCase()}`
    : `Europarl_${key.toUpperCase()}`;
};

export const kCookiePopupLinkLabelsKeys = {
  [kCookiesInventory]: 'ppTitle4',
  [kCookiesPolicy]: 'ppTitle3',
  [kDataProtectionNotice]: 'ppTitle2',
};

export const kMovieVersions = {
  ov: 'original-version',
  dv: 'dubbed-version',
  ad: 'audio-description',
  isl: 'sign-language',
};

export const kMovies = {
  desktop: 'hero-movie-desktop',
  mobile: 'hero-movie-mobile',
};

export const pageLabelKeys = [
  [kIndex, 'pageLabelHome'],
  [kHowElectionsWork, 'pageLabelHowElectionsWork'],
  [kAfterTheElections, 'pageLabelAfterTheElections'],
];
